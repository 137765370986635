import { Formik, FormikValues } from 'formik'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import {
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
} from '../../../lib/constants'
import { formatPrice } from '../../../lib/utilities/search'
import { addToDataLayer } from '../../../lib/utilities/vehicle'
import { findADeal } from '../../../store/reducers/search'
import styles from './search-form.module.scss'
import { Button, ButtonHierarchy } from '@/components/styleguide/Button'
import { ChipGroup } from '@/components/styleguide/Chip'
import { Dropdown } from '@/components/styleguide/Dropdown'
import { InternalLink } from '@/components/styleguide/Link'
import { Size, Theme } from '@/types/system'
import { useExperiment } from '@/lib/hooks/useExperiment'

const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false,
})

export type SearchFormProps = {
  initialVehicleType: number
  hasRedirectToLandingPage?: boolean
  hideToggle?: boolean
}

const savedSearchReferences = [
  { ref: '4d56b4a8c34e632f514f4fa957ad65d8', label: 'Under £250/pm' },
  { ref: 'b960d43d5d3c027d4926af1a646c536f', label: 'Family cars' },
  { ref: 'ddacda9c0bcc3b550c12c696e7049b18', label: 'Electric cars' },
]

export const SearchForm = ({
  initialVehicleType,
  hasRedirectToLandingPage = false,
  hideToggle = false,
}: SearchFormProps) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [experimentGroup] = useExperiment()

  const handleChangeVehicleType = (newType: number) => {
    addToDataLayer({
      event: 'homepage-search-filter',
      action: newType.toString(),
      experimentGroup,
    })
    if (hasRedirectToLandingPage) {
      router.replace(`/${newType === FILTER_VEHICLE_TYPES_CARS ? '' : 'van-leasing'}`)
    }
  }

  const onCarSearchSubmit = (values: FormikValues) => {

    if (typeof window.ttq !== 'undefined') {
      window.ttq.track('Search', {
        // eslint-disable-next-line camelcase
        content_id: `Homepage search ${values.type} ${values.monthlyMax} ${values.vehicleType}`,
        // eslint-disable-next-line camelcase
        content_type: 'product',
        // eslint-disable-next-line camelcase
        content_name: 'Homepage search',
        quantity: 1,
        value: null,
        price: null,
        currency: 'GBP',
      })
    }

    addToDataLayer({
      event: 'homepage-search',
      lease: values.type,
      'monthly-amount': values.monthlyMax,
      action: values.vehicleType,
      experimentGroup,
    })
    dispatch(
      findADeal({
        leaseTypes: values.type,
        monthlyPaymentMax: values.monthlyMax,
        vehicleTypes: values.vehicleType,
      }),
    )
    router.push('/car-leasing/search')
  }

  const onVanSearchSubmit = (values: FormikValues) => {
    addToDataLayer({
      event: 'homepage-search',
      lease: FILTER_LEASE_TYPES_BUSINESS,
      'monthly-amount': values.monthlyMax,
      action: FILTER_VEHICLE_TYPES_VANS,
      experimentGroup,
    })
    dispatch(
      findADeal({
        leaseTypes: FILTER_LEASE_TYPES_BUSINESS,
        monthlyPaymentMax: values.monthlyMax,
        vehicleTypes: FILTER_VEHICLE_TYPES_VANS,
        wheelbase: values.wheelbase === 0 ? null : values.wheelbase,
      }),
    )
    router.push('/van-leasing/search')
  }

  const onLinkClick = (item: { label: string }) => {
    addToDataLayer({
      event: 'homepage-search-chip',
      label: item.label,
      experimentGroup,
    })
  }

  return (
    <div className={styles.main}>
      <Formik
        initialValues={{
          type: FILTER_LEASE_TYPES_PERSONAL,
          monthlyMax: 999998,
          wheelbase: 0,
          vehicleType: initialVehicleType,
        }}
        enableReinitialize
        onSubmit={values => {
          if (values.vehicleType === FILTER_VEHICLE_TYPES_CARS) {
            onCarSearchSubmit(values)
          } else if (values.vehicleType === FILTER_VEHICLE_TYPES_VANS) {
            onVanSearchSubmit(values)
          }
        }}>
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <form
              className={styles.wrapper}
              onSubmit={handleSubmit}>
                {!hideToggle &&
                  <ChipGroup
                    theme={Theme.dark}
                    onChipChange={newType => {
                      handleChangeVehicleType(newType as number)
                      setFieldValue('vehicleType', newType)
                    }}
                    active={[values.vehicleType]}
                    chips={[
                      {
                        chipId: FILTER_VEHICLE_TYPES_CARS,
                        label: 'Car leasing',
                      },
                      {
                        chipId: FILTER_VEHICLE_TYPES_VANS,
                        label: 'Van leasing',
                      },
                    ]}
                  />
                }
              <div className={styles.form}>
                {values.vehicleType === FILTER_VEHICLE_TYPES_CARS && (
                  <Dropdown
                    className={styles.field}
                    label="Lease type"
                    isCompact
                    name="type"
                    id="type"
                    options={[
                      { label: 'Personal', value: FILTER_LEASE_TYPES_PERSONAL },
                      { label: 'Business', value: FILTER_LEASE_TYPES_BUSINESS },
                    ]}
                  />
                )}
                {values.vehicleType === FILTER_VEHICLE_TYPES_VANS && (
                  <Dropdown
                    className={styles.field}
                    label="Wheelbase"
                    isCompact
                    name="wheelbase"
                    id="wheelbase"
                    options={[
                      { label: 'Any', value: 0 },
                      { label: 'Small', value: 10 },
                      { label: 'Medium', value: 11 },
                      { label: 'Big', value: 12 },
                    ]}
                  />
                )}
                <span className={styles.separator}></span>
                <Dropdown
                  label="Monthly budget"
                  isCompact
                  className={styles.field}
                  name="monthlyMax"
                  id="monthlyMax"
                  options={[{ value: 999998, label: 'Any budget' }].concat(
                    [
                      150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 900,
                      950, 1000,
                    ].map(item => ({ label: formatPrice(item), value: item })),
                  )}
                />
                <MediaQuery minWidth={992}>
                  {matches => (
                    <Button
                      icon={matches ? 'Search' : ''}
                      label={matches ? 'Search deals' : 'Search'}
                      size={Size.Large}
                      hierarchy={ButtonHierarchy.Filled}
                      type="submit"
                    />
                  )}
                </MediaQuery>
              </div>
            </form>
            {values.vehicleType === FILTER_VEHICLE_TYPES_CARS && (
              <div className={styles.pills}>
                <MediaQuery minWidth={992}>
                  {matches => (
                    <p className={styles.pills__title}>Popular{matches && ' searches'}:</p>
                  )}
                </MediaQuery>
                <ul className={styles.pills__container}>
                  {savedSearchReferences.map((item, idx) => (
                    <li key={idx}>
                      <InternalLink
                        hierarchy={ButtonHierarchy.Borderless}
                        theme={Theme.dark}
                        size={Size.Medium}
                        hasUnderline
                        onClick={() => onLinkClick(item)}
                        href={`/car-leasing/search/${item.ref}`}>
                        {item.label}
                      </InternalLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  )
}
