import { Size } from '@/types/system'
import React from 'react'

import { Button } from '../Button'
import { ButtonComponentProps, ButtonHierarchy } from '../Button/Button'

export type ChipProps = {
  chipId: string | number
  label: string
  chipType: ButtonHierarchy.ChipChoice | ButtonHierarchy.ChipFilter
  action?: ({ chipId, label }: { chipId: string | number; label: string }) => void
  isSelected?: boolean
} & Omit<ButtonComponentProps, 'size' | 'hierarchy'>
export const Chip = ({ chipId, label, action, chipType, ...restProps }: ChipProps) => {
  return (
    <Button
      {...restProps}
      label={label}
      size={Size.Medium}
      hierarchy={chipType}
      onClick={e => {
        e.preventDefault()

        if (action) {
          action({ chipId, label })
        }
      }}
    />
  )
}
