import { FieldHookConfig, useField } from 'formik'
import dynamic from 'next/dynamic'

import styles from './dropdown.module.scss'
import { Size } from '@/types/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export type DropdownOption = {
  value: string | number
  label?: string
}

export type DropdownProps = {
  id: string
  disabled?: boolean
  label?: string
  hint?: string
  options: DropdownOption[]
  emptyOptionLabel?: string
  handleOnChange?: (value: string) => void
  isCompact?: boolean
  labelPrefix?: string
} & FieldHookConfig<'string'>

export const Dropdown = ({
  id,
  hint,
  label,
  disabled,
  options,
  emptyOptionLabel,
  handleOnChange,
  isCompact = false,
  labelPrefix = '',
  className,
  required,
}: DropdownProps) => {
  const [field, meta, { setValue }] = useField({
    name: id,
    value: emptyOptionLabel || options.length === 0 ? 'empty-option' : undefined,
  })

  return (
    <div
      className={`${styles.main} ${isCompact ? styles.compact : ''} ${className ? className : ''}`}
      data-error={!!meta.error}
      data-disabled={disabled}>
      {label && (
        <label
          htmlFor={id}
          className={styles.label}>
          {label}
          {required && '*'}
        </label>
      )}
      <div className={styles.select}>
        <select
          name={field.name}
          onChange={e => {
            if (e) {
              setValue(e.target.value)
              if (handleOnChange) {
                handleOnChange(e.target.value)
              }
            }
          }}
          disabled={disabled}
          className={`${styles.control} ${
            field.value === 'empty-option' || field.value === '' ? styles['default-selected'] : ''
          }`}
          value={field.value}>
          {(emptyOptionLabel || options.length === 0) && (
            <option
              value="empty-option"
              className={styles.placeholder}>
              {emptyOptionLabel ? emptyOptionLabel : 'Select an option'}
            </option>
          )}
          {options.map(option => {
            return (
              <option
                key={option.value}
                value={option.value}>
                {labelPrefix}
                {option.label ? option.label : option.value}
              </option>
            )
          })}
        </select>
        {!isCompact && (
          <Icon
            customClass={styles.icon}
            name="ChevronDown"
            size={Size.Small}
          />
        )}
      </div>
      {(hint || meta.error) && (
        <span
          className={styles.foot}
          data-error={!!meta.error}>
          {meta.error ? meta.error : hint}
        </span>
      )}
    </div>
  )
}
