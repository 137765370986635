import { ButtonHierarchy } from '@/components/styleguide/Button'
import { Theme } from '@/types/system'
import React, { useEffect, useRef, useState } from 'react'

import { Chip, ChipProps } from './Chip'
import styles from './chip.module.scss'

export interface ChipGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  chips: Omit<ChipProps, 'hierarchy' | 'chipType'>[]
  theme?: Theme
  onChipChange?: (chipId: ChipProps['chipId']) => void
  active?: ChipProps['chipId'][]
  doesScrollIntoViewOnLoad?: boolean
  allowMultiple?: boolean
  hasScroll?: boolean
}

export const ChipGroup = ({
  chips,
  theme = Theme.light,
  className,
  onChipChange,
  active,
  doesScrollIntoViewOnLoad = false,
  allowMultiple = false,
  hasScroll = true,
}: ChipGroupProps) => {
  const [selected, setSelected] = useState<(string | number)[]>(active || [chips[0].chipId])
  const groupRef = useRef<HTMLDivElement>(null)
  const chipType = allowMultiple ? ButtonHierarchy.ChipFilter : ButtonHierarchy.ChipChoice

  const scrollSelectedChipIntoView = () => {
    const selectedIndex = chips.findIndex(chip => selected.includes(chip.chipId))
    const selectedChip = groupRef.current?.querySelector(`button:nth-of-type(${selectedIndex + 1})`)
    selectedChip?.scrollIntoView(false)
  }

  useEffect(() => {
    if (doesScrollIntoViewOnLoad) {
      scrollSelectedChipIntoView()
    }
    // eslint-disable-next-line
  }, [doesScrollIntoViewOnLoad])

  useEffect(() => {
    if (!selected) {
      setSelected([chips[0].chipId])
    }
  }, [chips, selected])

  const handleOnChipChange = (chipId: ChipProps['chipId']) => {
    if (allowMultiple) {
      setSelected(prev => {
        const selectedCopy = [...prev]
        if (selectedCopy.includes(chipId)) {
          return selectedCopy.filter(item => item !== chipId)
        } else {
          return [chipId, ...selectedCopy]
        }
      })
    } else {
      setSelected([chipId])
    }
    if (onChipChange) {
      onChipChange(chipId)
    }
  }

  useEffect(() => {
    if (active) {
      setSelected(active)
    }
  }, [active])

  return (
    <div
      className={`${styles.group} ${className ? className : ''}`}
      data-has-scroll={hasScroll}
      ref={groupRef}>
      {allowMultiple &&
        active &&
        active.map(activeChip => {
          const selectedChip = chips.filter(chip => chip.chipId === activeChip)[0]
          return (
            <Chip
              {...selectedChip}
              chipType={chipType}
              key={activeChip}
              isSelected={true}
              action={() => handleOnChipChange(activeChip)}
              theme={theme}
            />
          )
        })}
      {allowMultiple &&
        active &&
        chips
          .filter(chip => active.length === 0 || !active.includes(chip.chipId))
          .map(chip => (
            <Chip
              {...chip}
              chipType={chipType}
              key={chip.chipId}
              isSelected={false}
              action={() => handleOnChipChange(chip.chipId)}
              theme={theme}
            />
          ))}
      {!allowMultiple &&
        chips.map(chip => (
          <Chip
            {...chip}
            chipType={chipType}
            key={chip.chipId}
            isSelected={selected.includes(chip.chipId)}
            action={() => handleOnChipChange(chip.chipId)}
            theme={theme}
          />
        ))}
    </div>
  )
}
